import React from "react";
import { Section, SubSection } from "~components/text-helpers";
import Latex from "react-latex";
import { graphql, Link } from "gatsby";
import Img, { FixedObject, FluidObject } from "gatsby-image";
import { css } from "@emotion/react";
import { Table } from "antd";

import {
  Code,
  Response,
  Attribute,
  IL,
  Remark,
  VSpace,
  SideBySideCode,
  APIReferenceiTHOR,
} from "~components/documentation";

export default function iTHORDocReference() {
  return (
    <APIReferenceiTHOR columnKey="scenes" pageName="Scene">
      <Section sectionTitle="Scene Distribution" emoji="bathtub">
        <p>
          There are 120 scenes in iTHOR, evenly spread across kitchens, living
          rooms, bedrooms, and bathrooms. The list of scenes is defined as:
        </p>
        <Code
          lines={[
            'kitchens = [f"FloorPlan{i}" for i in range(1, 31)]',
            'living_rooms = [f"FloorPlan{200 + i}" for i in range(1, 31)]',
            'bedrooms = [f"FloorPlan{300 + i}" for i in range(1, 31)]',
            'bathrooms = [f"FloorPlan{400 + i}" for i in range(1, 31)]',
            "",
            "scenes = kitchens + living_rooms + bedrooms + bathrooms",
          ]}
        />
        <Response
          lines={[
            '["FloorPlan1", "FloorPlan2", "FloorPlan3", {...}, "FloorPlan430"]',
          ]}
        />
        <Remark>
          Explore the scenes in the <Link to="/demo">demo</Link>!
        </Remark>
      </Section>
      <Section sectionTitle="Scene Utility" emoji="ship">
        <p>
          We provide a cached alias to the above definitions using the{" "}
          <IL>ithor_scenes</IL> method:
        </p>
        <SideBySideCode
          example={
            <>
              <Code
                lines={[
                  "controller.ithor_scenes(",
                  "    include_kitchens=True,",
                  "    include_living_rooms=True,",
                  "    include_bedrooms=True,",
                  "    include_bathrooms=True",
                  ")",
                ]}
              />
              <Response
                lines={[
                  "[",
                  '    "FloorPlan1",',
                  '    "FloorPlan2",',
                  "    {...},",
                  '    "FloorPlan201",',
                  "    {...},",
                  '    "FloorPlan301",',
                  "    {...},",
                  '    "FloorPlan430",',
                  "]",
                ]}
              />
            </>
          }
          argsTitle="Parameters"
        >
          <Attribute
            hashPrefix="ithor-scenes"
            name="include_kitchens"
            type="bool"
            default="True"
            description={
              <p>
                Includes the 30 kitchens (i.e., FloorPlan[1:30]) in the returned
                list when True.
              </p>
            }
          />
          <Attribute
            hashPrefix="ithor-scenes"
            name="include_living_rooms"
            type="bool"
            default="True"
            description={
              <p>
                Includes the 30 living rooms (i.e., FloorPlan[201:230]) in the
                returned list when <IL>True</IL>.
              </p>
            }
          />
          <Attribute
            hashPrefix="ithor-scenes"
            name="include_bedrooms"
            type="bool"
            default="True"
            description={
              <p>
                Includes the 30 bedrooms (i.e., FloorPlan[301:330]) in list when{" "}
                <IL>True</IL>
              </p>
            }
          />
          <Attribute
            hashPrefix="ithor-scenes"
            name="include_bathrooms"
            type="bool"
            default="True"
            description={
              <p>
                Includes the 30 bathrooms (i.e., FloorPlan[401:430]) in list
                when <IL>True</IL>.
              </p>
            }
          />
        </SideBySideCode>
      </Section>
      <Section sectionTitle="Train/Val/Test Splits" emoji="banana">
        <p>
          <Latex>
            It is common to train agents on a subset of scenes, in order to
            validate and test how well they generalize to new scenes and
            environments. For each room type, it is standard practice to treat
            the first $$20$$ scenes as training scenes, the next $$5$$ scenes as
            validation scenes, and the last $$5$$ scenes as testing scenes.
          </Latex>
        </p>
      </Section>
    </APIReferenceiTHOR>
  );
}
